/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable no-dupe-else-if */
/* eslint-disable prefer-const */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/jsx-key */
/* eslint-disable no-self-assign */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-empty-interface */
// import { NextPage, GetStaticPaths } from "next";
import React from "react";
// import { useRef } from "react";
// import { SitePluginFieldsEnum } from "../../../generated/graphql-types";

// export const getStaticProps: GetStaticProps = async () => {};

// export const getStaticPaths: GetStaticPaths = async (s: any) => {
//   console.log("TESTing");
//   console.log(s);
// };

// export const TypePage: NextPage<TypePageProps> = (type) => {
//   let test = "";
//   test = type;
//   console.log(test);
//   return test.slug;
// };
// export default TypePage;

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { graphql, PageProps } from "gatsby";
import {
  Expander,
  Hero,
  HillsAndValleysCarousel,
  Quote,
  Stories,
} from "../../components";
// eslint-disable-next-line import/named
import { buildMenu, Layout } from "../../layouts";
import { getCleanedRTF } from "@bond-london/graphcms-rich-text";
// import { t } from "i18next";
export interface TypePageProps {
  slug: string;
}
import inline from "../../images/pipwareheading.jpg";
import quoteimage from "../../images/pipware-quote.png";
import challangeimg from "../../images/pipware-challngesimage.jpg";
import continousimg from "../../images/pipware-continous.jpg";
import energyimg from "../../images/pipware-energy.jpg";
import digitalimg from "../../images/pipware-digital.jpg";
import classNames from "classnames";
import { GA_HillsAndValleysCarouselContent } from "../../components/GA_HillsAndValleysCarouselContent";
import { Ga_pipwareCardModal } from "../../components/GA_pipwareCardModal";
const inlineImage = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { inline },
      srcSet: inline,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};

const challangeimgs = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { challangeimg },
      srcSet: challangeimg,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};

const digital = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { digitalimg },
      srcSet: digitalimg,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};
const energy = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { energyimg },
      srcSet: energyimg,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};
const continuous = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { continousimg },
      srcSet: continousimg,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};

const quoation_image = {
  company: null,
  country: "Australia and New Zealand",
  image: {
    layout: "constrained",
    backgroundColor: "#0898a8",
    images: {
      fallback: {
        sizes: "(min-width: 600px) 600px, 100vw",
        src: { quoteimage },
        srcSet: quoteimage,
        sources: [],
        layout: "constrained",
        width: 600,
      },
    },
    width: 600,
    height: 600,
  },
  jobTitle: "Mercede cum Possimus Vindices",
  link: "/people/skipp-williamson-et",
  linkedin: "https://www.linkedin.com/in/skippwilliamson/",
  name: "Nobis Sacrificio",
  quote:
    "In 30 years of consulting, I have not seen a change that will be as transformative and as positive for most of our clients’ economics as energy transition. We first started working on energy efficiency 20 years ago, but the last five years have created exponentially accelerating changes in the pace of requirements and reductions in costs curves. Great opportunities are opening for those who frame the problems well and act fast.",
  sector: "Infrastructure",
};

const ResearchDocument: React.FC<
  PageProps<Pick<Query, "graphCmsSectorPage" | "allGraphCmsSectorPage">>
> = ({
  path,
  data: {
    graphCmsSectorPage: sectorPage,
    // allGraphCmsSectorPage: { edges },
  },
}) => {
  sectorPage.statement = {
    cleaned: [
      {
        children: [
          {
            text: "The global energy transition brings specific challenges, but also offers immense opportunity.",
          },
        ],
        type: "heading-three",
      },
      {
        children: [
          {
            text: "Depauperatio nam muneris consiliarorum mi accessum ordinationem praesentes aspirat. Justo nunc esse impedient mi a 20% gloriosnm at deplorata me 9030, soluni respublica per notitiam et 40‑60% eu 1025 sed 50% me 9030.",
          },
        ],
        type: "paragraph",
      },
      {
        children: [
          {
            text: "Depauperatio nam muneris consiliarorum mi accessum ordinationem praesentes aspirat. Justo nunc esse impedient mi a 20% gloriosnm at deplorata me 9030, soluni respublica per notitiam et 40‑60% eu 1025 sed 50% me 9030.",
          },
        ],
        type: "paragraph",
      },
      {
        children: [
          {
            text: "Depauperatio nam muneris consiliarorum mi accessum ordinationem praesentes aspirat. Justo nunc esse impedient mi a 20% gloriosnm at deplorata me 9030, soluni respublica per notitiam et 40‑60% eu 1025 sed 50% me 9030.",
          },
        ],
        type: "paragraph",
      },
    ],
  };
  sectorPage.businessChallengesIntroduction =
    "Nisl fortunae strictus est eminentiam id dui arduas religionis, unde vestimentorum cum eiusdem crimen contrarii in esse in atque parcam periculose:";
  sectorPage.quote =
    "Supererant est refugium praefixum et abrennuntiat per hic uidem februarii wisi sed nunc arcana. Irrevocabilem iure memoria o proin erat ut parum si litteras eius rem purus mi netus non investigarunt. Quo cumque religionis te vel a ostentari/incarcerata augusta nisi neque et ad pactum nunc nisi - ti mi s constantiam colubros meritorius typi rerum pretia quo.";

  const collection = {
    id: "test",
    showName: true,
    name: "Hac piscis pronunciam adulterium nisi metus o neglexi, rationibus louor poenam per occiditur, te omnis potenti versus quo aedificatione honorem est promovebunt et arcu desolationem",
    pageLinks: [
      {
        id: "dshjkfhd",
        optionalTitle: "TEST",
        narrowImage: digital,
        highlightName: "",
        title: "Digital Office Transformation",
        link: "/service/digital-transformation",
        Images: digital,
        messageRTF: [
          {
            type: "paragraph",
            children: [
              {
                text: "Driving successful transformations across government departments, financial services, institutions and corporate head offices by taking an end‑to‑end approach that integrates all layers of an organisation.",
              },
            ],
          },
        ],
      },
      {
        id: "dshjkfh1d",
        optionalTitle: "TEST",
        narrowImage: continuous,
        highlightName: "",
        title: "Continuous Improvement",
        link: "/service/continuous-improvement",
        Images: continuous,
        messageRTF: [
          {
            type: "paragraph",
            children: [
              {
                text: "Hard‑wiring continuous ideas pipelines into an operation to make performance transparent at all levels of the business, with single‑point accountabilities and competencies to deliver exceptional results.",
              },
            ],
          },
        ],
      },
      {
        id: "dshjkf2hd",
        optionalTitle: "Energy Transition",
        narrowImage: energy,
        highlightName: "",
        title: "Energy Transition",
        link: "/service/energy-transition",
        Images: energy,
        messageRTF: [
          {
            type: "paragraph",
            children: [
              {
                text: "Unlocking the mounting benefits for investment in energy transition by enhancing emissions reduction and delivering positive economic outcomes.",
              },
            ],
          },
        ],
      },
      {
        id: "dshjkf2hd",
        optionalTitle: "Capital Projects",
        narrowImage: energy,
        highlightName: "",
        title: "Capital Projects",
        link: "/service/capital-projects/",
        Images: energy,
        messageRTF: [
          {
            type: "paragraph",
            children: [
              {
                text: "Maximising value from capital investments by helping clients to deliver shorter schedules, lower costs and provide better clarity for large infrastructure projects and portfolios.",
              },
            ],
          },
        ],
      },
    ],
  };
  if (!ResearchDocument) return null;
  return (
    <Layout
      pagePath={path}
      menu={buildMenu(sectorPage?.menu)}
      footerMenu={buildMenu(sectorPage?.footerMenu)}
      keywords={sectorPage?.keywords}
      title={"PIPWARE | Partners in Performance Insights"}
      description={sectorPage?.description}
    >
      <Hero
        title={"PIPware"}
        image={inlineImage}
        // colour="MediumBlue"
        short={true}
        treatment={sectorPage?.heroTreatment}
        showSearch={true}
      />
      <Expander
        paragraphsRTF={getCleanedRTF(sectorPage.statement)}
        introduction={sectorPage.businessChallengesIntroduction}
        buttonlable="Click"
        buttonlink="#"
        callouts={sectorPage.businessChallenges.map((c) => ({
          title: c.headline,
          contentRTF: getCleanedRTF(c.content),
        }))}
      />
      <Quote quote={sectorPage.quote} profile={quoation_image} />
      <section
        data-component="Researchblock"
        className={classNames(
          "py-section relative max-width z-10 bg-dark-blue"
        )}
      >
        <center>
          <table className="w-desktop-6-cols">
            <tr className="text-white-1">
              <td>
                {" "}
                <div className="self-center text-center text-white-1">
                  {"Lorem Ipsum"}
                </div>
              </td>
              <td>
                {" "}
                <div className="self-center text-center text-white-1">
                  {"Lorem Ipsum"}
                </div>
              </td>
              <td>
                {" "}
                <div className="self-center text-center text-white-1">
                  {"Lorem Ipsum"}
                </div>
              </td>
              <td>
                {" "}
                <div className="self-center text-center text-white-1">
                  {"Lorem Ipsum"}
                </div>
              </td>
              <td>
                {" "}
                <div className="self-center text-center text-white-1">
                  {"Lorem Ipsum"}
                </div>
              </td>
            </tr>
          </table>
        </center>
      </section>
      <HillsAndValleysCarousel
        key={collection.id}
        id={collection.id}
        title={collection.showName ? collection.name : undefined}
        contents={collection.pageLinks.map((s, index) => {
          return (
            <GA_HillsAndValleysCarouselContent
              id={s.id}
              index={index}
              key={s.id}
              image={s.Images}
              alt={"Image"}
              title={s.title}
              messageRTF={s.messageRTF}
              link={s.link}
              highlightName={s.highlightName}
              narrowImage={s.narrowImage}
            />
          );
        })}
      />
      <section
        data-componet="tets"
        className="relative max-width z-10 ga-pipwarecustomsection"
      >
        {/* <h5
          className={classNames(
            "ga-ptb-30 row-start-1 col-start-2 col-span-12 text-center h7  ga-block-heading ga-font-style-s24 ga-line-height-42 "
          )}
        >
          <center>{"Lorem Ipsum"}</center>
        </h5>
        <div className="row w-full">
          <table className="w-full">
            <tr>
              <td className="p-30px pl-10px">
                <div className="border-2 col-span-4 h-50px text-center p-30px h-300px">
                  Custodiae lacus me partinuculam
                </div>
              </td>
              <td className="p-30px">
                <div className="border-2 col-span-4 h-50px text-center p-30px h-300px">
                  Custodiae lacus me partinuculam
                </div>
              </td>
              <td className="p-30px pr-10px">
                <div className="border-2 col-span-4 h-50px text-center p-30px h-300px">
                  Custodiae lacus me partinuculam
                </div>
              </td>
            </tr>
          </table>
        </div> */}
        <div className="p-10px">
          <div className="w-full h-300px border-2 p-30px">
            Ac ipsum quas ac ut amplissima si odit magni praeiudicata, nomine
            felis
          </div>
          {/* <textarea className="w-full" rows={5} cols={10}></textarea> */}
          <br></br>
          <a className="float-right underline1" rel="noreferrer" href="#">
            Discover More
          </a>
          <br></br>
        </div>
        <br></br>
        <Ga_pipwareCardModal
          id={""}
          title={"TEST"}
          content={"TESTContent"}
          link={"URL"}
        />
      </section>
      <Stories
        title={"Pro bono success stories"}
        image={challangeimgs}
        stories={[
          {
            link: "/case-study/naccho",
            title:
              " Delivered a comprehensive business case for Federal funding in the amount of ~$49 million over five years to uplift connectivity and digital capability to improve telehealth opportunities for members.",
            strong: "NACCHO:",
          },
          {
            link: "/case-study/schoolsplus",
            title:
              " Refreshed donor outreach strategy so it is relevant, insightful, and based on data from 39 donors.",
            strong: "School Plus:",
          },
          {
            link: "/case-study/rspca",
            title:
              " Designed a new pricing model which would generate revenue for RSPCA AFS to expand the standards into new areas and reduce admin burden for licensees.",
            strong: "RSPCA:",
          },
          {
            link: "/case-study/amigos-do-bem",
            title:
              " Amigos do Bem, a non-profit organisation, are one of the largest social projects in Brazil, assisting more than 75,000 people in the hinterland of Alagoas, Pernambuco, and Ceará.",
            strong: "AMIGOS DO BEM:",
          },
        ]}
      />
      {/* <Article
        side="right"
        id={""}
        image={challangeimgs}
        alt={""}
        detailsRTF={[
          {
            children: [
              {
                text: "Ac Testes’m Consuetudium Potissimum, innumeros si missae te circiter vectigal odio numero, liuius eum a illud iure at subsit praesenti. Plenus se Eum per v vinctas necessitas dolor nisi acerba ut potentiores modurn elit cvictiones at mirum ossibus. Liber o metus rem typi error laesae eodem abundanlia supremum si uidem Democriti laedis nam gratnlor ipsam, prophetico fortes, indignitatem symbola, pectore eos aut per glaebam similitudines, iure aenean successum proin neque eget, reputationi omnibus hac nostrud meritis. Ea amet et sem Opprimere Soluni Imperiosus quo troiano in unde m lapide utinam, se poenas odit alias quis id liberum w eget iniungimus, iste esse in ultricies ac litteras natus, hac axioma m adverso at crimine desideriorum nec proprio Operis sed Non’o virtutis romanorum.",
              },
            ],
            type: "heading-three",
          },
        ]}
        articlewithContent="YES"
        Articletitle="Custodiae lacus me partinuculam"
        heading={[
          {
            children: [
              {
                text: "Ac Testes’m Consuetudium Potissimum, innumeros si missae te circiter vectigal odio numero, liuius eum a illud iure at subsit praesenti. Plenus se Eum per v vinctas necessitas dolor nisi acerba ut potentiores modurn elit cvictiones at mirum ossibus. Liber o metus rem typi error laesae eodem abundanlia supremum si uidem Democriti laedis nam gratnlor ipsam, prophetico fortes, indignitatem symbola, pectore eos aut per glaebam similitudines, iure aenean successum proin neque eget, reputationi omnibus hac nostrud meritis. Ea amet et sem Opprimere Soluni Imperiosus quo troiano in unde m lapide utinam, se poenas odit alias quis id liberum w eget iniungimus, iste esse in ultricies ac litteras natus, hac axioma m adverso at crimine desideriorum nec proprio Operis sed Non’o virtutis romanorum.",
              },
            ],
            type: "paragraph",
          },
        ]}
      /> */}
      <p className="col-span-12 col-start-2 text-center p-20px bg-light-blue">
        To learn more or to contribute to this great organisation, please visit
        &nbsp;
        <b>
          <a href="https://www.amigosdobem.org/" className="text-dark-blue">
            https://www.amigosdobem.org/
          </a>
        </b>
      </p>
      <br></br>
    </Layout>
  );
};

export default ResearchDocument;

export const IndexQuery = graphql`
  {
    graphCmsSectorPage {
      sectorType
      pageTitle
      keywords
      description
      menu {
        ...MenuFragment
      }
      businessChallengesIntroduction
      businessChallenges {
        headline
        id
        content {
          cleaned
        }
      }
      heroImage {
        ...ImageFragment
      }
      statement {
        cleaned
      }
      heroVideo {
        ...VideoFragment
      }
      heroTreatment
      id
      keyStatistic
      leadership {
        ...ProfileFragment
      }
      leadershipTitle
      quote
      quoteFrom {
        ...ProfileFragment
      }
      infographicIntroduction {
        cleaned
      }
      infographicDetails {
        cleaned
      }
      genericInfographicText {
        cleaned
      }
      sector
      slug
      statisticDetails
      storiesTitle
      storiesImage {
        ...ImageFragment
      }
      successes {
        __typename
        ... on GraphCMS_CaseStudy {
          ...CaseStudyReferenceFragment
        }
        ... on GraphCMS_Story {
          ...StoryReferenceFragment
        }
        ... on GraphCMS_Insight {
          ...InsightReferenceFragment
        }
      }
      form {
        ...FormFragment
      }
    }
    allGraphCmsSectorPage {
      edges {
        node {
          sector
          heroImage {
            id
            url
          }
          pageTitle
        }
      }
    }
  }
`;
